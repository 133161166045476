<template lang="">
    <div class="kind-page-wrapper">
        <div v-if="$root.partner?.features?.skills?.enabled" class="mb-4 d-flex flex-row pe-4">
            <div class="d-flex flex-row flex-fill">
                <span class="pe-2 pt-2">Filters:</span>
                <select class="form-select categories"
                    v-model="current.category"
                    >
                    <option :selected="[option == current.category? 'selected' : '']" 
                        v-for="(option, index) in categories" :key="index"
                        >{{option}}</option>
                </select>
            </div>
            <div>
                <a class="btn btn-light bg-purple rounded-4 shadow-sm text-light px-3" 
                    @click="newAIFormulaIntent">
                    <span class="material-icons-outlined float-start me-2">
                    science
                    </span>
                    New AI Skill</a>
            </div>
        </div>


        <div class="kind-ai d-flex flex-wrap">
            <CardAi v-for="(card, index) in collection" :data="card" :key="index" 
                :class="[/All/.test(current.category)? '' : 'd-none']"
                :select="select" class="predefined-formula mb-4"></CardAi>
            <CardFormula v-for="(formula, index) in formulas" :data="formula"  :key="index"
                :class="[/All|Prompts/.test(current.category)? '' : 'd-none']"
                :select="useFormula" class="user-formula mb-4"></CardFormula>
        </div>
    </div>
</template>
<script>

import usecases from "@/assets/data/ai-usecases.js";
import CardAi from './CardAi';
import CardFormula from './CardFormula';


export default {
    name: 'KindAi',
    
    components: {
        CardAi,
        CardFormula
    }, 

    props: {
        kind: String
    },


    data() {
        return {
            titleSuffix: 'Ai: ',
            current: {
                category: 'All', 
                usecase: null, 
                /** the opportunity for which the AI strategy will be created */
                opportunity: null
            }, 

            formulas: [
                // {title: 'One', excerpt: 'Some information about this formula'},
                // {title: 'Two', excerpt: 'Additional information about the formula number "two"'},
            ]
        }
    }, 


    computed: {
        categories() {
            var all = ['All', 'Prompts'];

            this.collection.forEach((item) => {
                item
                // if(item.categories && item.categories != '' && item.categories.toLowerCase() != 'white label') {
                //     let cats = item.categories.split(',')
                //     all = all.concat(cats)
                // }
            });
            var unique = [...new Set(all.sort())];
            // console.log(unique)

            return unique;
        }, 


        category() {
            return this.current.category;
        },


        collection() {
            return usecases.filter( (item)=> {
                let hide = item.name.match(/^Text\sEditing/) || item.name.match(/^Song\sLyrics/) || item.name.match(/^Story\sPlot/)?
                    true : false;
                return !hide;
            });
        }
    },


    watch: {
        category(newVal) {
            newVal
            // console.log(newVal);
        }
    },


    methods: {
        /**
         * Select an AI usecase
         * 
         * 
         * @param {Object} data Usecase as defined in ai-usecases.js
         * @param {Object} data.name
         * @param {Object} data.description
         * @param {Object} data.slug
         * @param {Object} data._id
         * @param {Object} data.contextInputs
         * 
         * 
         */
		select(usecase) {
            // console.log('select', usecase);

            this.current.usecase = usecase;

            this.prepareStrategyData(/** usecase */ usecase, (usecase, context) => {
                setTimeout(() => {
                    this.createIAContent(usecase, context);
                }, 500);
            });
		}, 


        /**
         * Open formula runner window with user generated prompt
         * 
         */
        useFormula(formula, action, $event) {
            $event.preventDefault();

            if(action == 'edit') {
                this.$root.editUserAIFormula(formula);
            } else if(action == 'open') {
                // this.$root.notify('Use this formula ('+ formula.title +')');
                this.$root.runUserAIFormula(formula);
            }
            // console.log(formula);
        },



        prepareStrategyData(usecase, callback) {
            let scope = this;
            let modal = this.$root.prepareStrategyData(
                {
                    title: 'Create ' + usecase.name,
                    buttons: {
                        okay: {
                            callback: async (component) => {
                                /**
                                 * component.data
                                 * component.data.title
                                 * component.data.excerpt
                                 * component.data.opportunity_id
                                 * component.data.opportunity_name
                                 * component.data.opportunity_title
                                 * 
                                 */
                                let context = {...component.data, ...{
                                        /**
                                         * Overwrite title to add suffix 
                                         * 
                                         * TODO: strategy types must be a category or a tag not a "title suffix"
                                         */
                                        title: scope.titleSuffix + component.data.title
                                    }
                                };

                                typeof callback == 'function' && callback.apply(null, [usecase, context]);
                            }
                        }
                    }
                }
            );

            setTimeout(() => {
                modal.content().data.title = usecase.name + ' (new)';
                modal.content().data.excerpt = usecase.caption;
            }, 0);

        }, 


        /**
         * Create a AI content ofr a given strategy
         * 
		 * @param {Object} usecase
		 * @param {Object} usecase.name
		 * @param {Object} usecase.slug
		 * @param {Object} usecase.description
		 * 
		 * @param {Object} opportunity
		 * @param {Object} opportunity.opportunity_id
		 * @param {Object} opportunity.opportunity_name
		 * @param {Object} opportunity.opportunity_title
         * 
         * 
         * 
         * 
         */
        async createIAContent(usecase, context) {

            let opportunity = {
                opportunity_id: context.opportunity_id,
                opportunity_name: context.opportunity_name,
                opportunity_title: context.opportunity_title
            };

            let selection = await this.$root.AIGenerateStrategy(usecase, opportunity);

            let request = {
                post_title: context.title,
                post_excerpt: context.excerpt,
                post_content: selection.text,
                meta_input: opportunity
            };
            
            this.$store.commit('SET_LOADING_STATE', true);
            let response = await this.$store.getters.wp.createAIStrategyForOpportunity(request);
            await this.$store.dispatch('reloadStrategies');
            this.$store.commit('SET_LOADING_STATE', false);

            if(response?.ID) {
                this.$root.openStrategy(response /** strategy data */);
            }



            /*
            let modal = this.$root.AIGenerateStrategy(usecase /** usecase * /, async (selection, usecase) =>{
                usecase
                // selection, usecase;
                // console.log(selection);
                // console.log(usecase);
                // console.log(context);

                let request = {
                    post_title: context.title,
                    post_excerpt: context.excerpt,
                    post_content: selection.text,
                    meta_input: {
                        opportunity_id: context.opportunity_id,
                        opportunity_name: context.opportunity_name,
                        opportunity_title: context.opportunity_title
                    }
                };


                this.$store.commit('SET_LOADING_STATE', true);
                let response = await this.$store.getters.wp.createAIStrategyForOpportunity(request);
                await this.$store.dispatch('reloadStrategies');
                this.$store.commit('SET_LOADING_STATE', false);

                if(response?.ID) {
                    // this.open(response);
                    this.$root.openStrategy(response /** strategy data * /);
                }
            });


			setTimeout(()=>{
				let content = modal.content();
					content.current.opportunity = {
                        opportunity_id: context.opportunity_id,
                        opportunity_name: context.opportunity_name,
                        opportunity_title: context.opportunity_title
                    };
			}, 100);
            */
        },

        newAIFormulaIntent() {
            // this.$root.notify('Ceeate a new AI Formula');

            let title = 'Create New AI Formula';
            let modal = this.$root.modal({
                title: title,
                context: null /* this is how the modal can interact wiht the parent component */,
                okay: {
                    label: 'CREATE',
                    visible: true,
                    disabled: false,
                    callback: async (component) => {
                        let title = component.data.title;
                        let excerpt = component.data.excerpt;
                        let inputs= {title, excerpt};
                        let response = await this.$root.createUserAIFormula(inputs);
                        await this.getFormulas();
                        console.log(response);

                        /** TODO: validate response */
                        if(response) {
                            this.$root.editUserAIFormula(response);

                        } else {
                            this.$root.notify('ERROR: unable to creeaet AI formula');
                        }


                        // let request = {
                        //     ID: form?.ID,
                        //     post_title: component.data.title,
                        //     post_excerpt: component.data.excerpt,
                        //     meta_input: {
                        //         workbook: component.data.workbook,
                        //         group: component.data.group,
						// 		type: component.data?.type?.toLowerCase() || 'generic'
                        //     }
                        // };
                        // this.editForm(request);
                    }
                }, 
            }, 'NewAIFormulaModalContent');


            setTimeout(() => {
				let content = modal.content();
                content;
            }, 0);
        }, 

        async getFormulas() {
            this.formulas = await this.$root.getUserAIFormulas();
            return this.formulas;
        }
    },

    async mounted() {
        this.current.usecase = null;
        this.formulas = await this.getFormulas();
    }
}
</script>


<style>
.kind-ai {
    min-width: 832px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.bg-purple {
    background-color: DarkOrchid !important;
}
</style>